import React from 'react'

export default function Download() {
  return (
    <div>
      This is the download page to download my Resume and any other things.
      
    </div>
  );
}
