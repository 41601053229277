import React from 'react';
import { Route, Routes} from 'react-router-dom';
import './App.css';

import NavTop from './components/NavTop';
import NavBottom from './components/NavBottom';

import Home from './pages/Home';
import BI_Intel from './pages/BI_Intel';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';

import Private_Policy from './pages/Private_Policy';
import Download from './pages/Download';

import Data_Projects from './pages/portfolio/Data_Projects';
import Coding from './pages/portfolio/Coding';
import Books from './pages/portfolio/Books';
import Graphic_Designs from './pages/portfolio/Graphic_Designs';
import Video from './pages/portfolio/Video';
import Websites from './pages/portfolio/Websites';
import SocAcct from './components/SocAcct';

import Logo from './assets/images/Logo.png';

function App() {
  return (
    <div className='container' >
      <header>
        <div>
          <img src={Logo} alt="logo" style={{width: '200px', height: '200px', margin: '5px'}} />
        </div>
        <SocAcct />
        <NavTop />
      </header>
      <content>
        <Routes>
          /* This is the main menu */
          <Route path="/" element={ <Home />}  />
          <Route path="/bi_intel" element={ <BI_Intel />}  />
          <Route path="/contact" element={ <Contact />}  />
          <Route path="/portfolio" element={ <Portfolio />}  />
          <Route path="/pripolicy" element={ <Private_Policy />}  />
          <Route path="/download" element={ <Download />}  />

          <Route path="/books" element={ <Books />}  />
          <Route path="/coding" element={ <Coding />}  />
          <Route path="/data" element={ <Data_Projects />}  />
          <Route path="/graphics" element={ <Graphic_Designs />}  />
          <Route path="/video" element={ <Video />}  />
          <Route path="/website" element={ <Websites />}  />
          
        </Routes>
      </content>
      <footer>
        <NavBottom />
        <div>
          Site is created by Jonathan Stroud
        </div>
      </footer>
    </div>
  );
}

export default App;
