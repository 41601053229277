import React from 'react';
import Bunny from '../assets/images/bunny.png'
import Design01 from '../assets/images/g_design01.png'
import Design02 from '../assets/images/g_design02.png'
import Website03 from '../assets/images/website03.png'

/*
"Hyeme viresco"
"I flourish in the winter"
*/

export default function Home() {
  return (
    <div>
        <div>
            <img src={Bunny} alt={Bunny} style={{width: '100%', height: '100%', margin: '5px'}} />
        </div>
        <div className='quotes'>
            <i>"Learn as if you will live forever, live like you will die tomorrow."</i> - Mahatma Gandhi
        </div>
        <div className='thelayout'>
            <p>
            Welcome to my website! I’m excited to share my skills, experience, and passion with you. Here, you’ll find a collection of my projects and the things I love to create. With over a two decades of expertise in graphic design, printing, and programming, I have developed a deep passion for web development, particularly using React to build innovative and user-friendly websites.
            </p>
            <div id='box'>
              <div>
                <img src={Website03} alt={Website03} style={{margin: '5px'}} />
              </div>
              <div>
                <p>
            In the realm of printing, I bring extensive hands-on experience, from operating HP digital presses to preparing complex files ready for print. My expertise includes variable data printing, barcode integration, and ensuring optimal print quality from concept to production. I’ve also worked across a wide range of platforms and technologies to deliver outstanding results.
            </p>
              </div>
            </div>
            <div id='box'>
              <div>
                <p>
              Though I’m based in Atlanta, Georgia, and have spent most of my life here, I’ve also lived in Long Beach, California, for a time, and both cities have shaped my creative and professional journey. Atlanta, in particular, has been an inspiring and vibrant home that fuels my work and hobbies.
              </p>
              <p>
              Beyond printing and web development, I am passionate about 3D design. I regularly use tools such as Adobe Substance Painter, Daz3D, and Marvelous Designer to bring my creative visions to life.
              </p>
              </div>
              <div>
              <img src={Design02} alt={Design02} style={{width: '80%', height: '80%', margin: '5px'}} />
              </div>
            </div>
            <div id='box'>
              <div>
              <img src={Design01} alt={Design01} style={{width: '90%', height: '90%', margin: '5px'}} />
              </div>
              <div>
                <p>
                Additionally, I’m advancing my skills in data analytics, working towards becoming a proficient data scientist. I’m also diving deep into Rust, honing my programming expertise with the goal of mastering this powerful language.
                </p>
                <p>
                Another area that fascinates me is electronics and embedded systems, where I enjoy building and experimenting with new ideas.
                </p>
                <p>
                As someone who thrives on learning, growth, and problem-solving, I’m always eager to take on new challenges. If you’re interested in collaborating or need assistance with a project, feel free to reach out through my contact page. Thank you for visiting my site—take your time exploring, and I hope you find something that resonates with you!
                </p>
              </div>
            </div>
        </div>
    </div>
  );
}
