import React from 'react'

export default function Data_Projects() {
  return (
    <div>
        This deals with the data analytic projects that I will be working on.
      
    </div>
  );
}
