import React from 'react'
import { Link } from 'react-router-dom';

export default function NavTop() {
  return (
    <nav className='nav'>
          <div className='menu-button'><Link to='/'>Home</Link></div>
          <div className='menu-button'><Link to='/bi_intel'>BI Intel</Link></div>
          <div className='menu-button'><Link to='/portfolio'>Portfolio</Link></div>
          <div className='menu-button'><Link to='/contact'>Contact</Link></div>
    </nav>
  );
}
