import React from 'react'
import Github from '../assets/images/logos/github_logo.png'
import Linked from '../assets/images/logos/linkedin_logo.png'
import Daz from '../assets/images/logos/daz3d_logo.png'
import Render from '../assets/images/logos/renderhub_logo.png'


export default function SocAcct() {
  return (
    <div className='top-middle'>
      <div className='social'>
        <a href='https://github.com/jstroud107' target='_blank' ><img src={Github} alt={Github} style={{width: '50px', height: '50px', margin: '5px'}} /></a>
        <a href='https://www.linkedin.com/in/jonathanstroudatl/' target='_blank'><img src={Linked} alt={Linked} style={{width: '50px', height: '50px', margin: '5px'}} /></a>
        <a href='https://www.daz3d.com/gallery/user/4941857582743552#gallery=newest&page=1' target='_blank'><img src={Daz} alt={Daz} style={{width: '50px', height: '50px', margin: '5px'}} /></a>
        <a href='https://www.renderhub.com/jstroud107/gallery' target='_blank'><img src={Render} alt={Render} style={{width: '50px', height: '50px', margin: '5px'}} /></a>
      </div>
        <div> <h1>"Hyeme viresco"</h1> </div>
    </div>
  );
}
