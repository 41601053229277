import React from 'react'
import NavPort from '../components/NavPort';

export default function Portfolio() {
  return (
    <div className='thelayout'>
      <div className='quotes'>
        <i>"When you give joy to other people, you get more joy in return. You should give a good thought to the happiness that you can give out."</i> - Eleanor Roosevelt
      </div>
     {/*<NavPort /> */} 
      <div style={{color: 'red'}}>
        <p>
          Currently working on which projects to upload to the site. Check back to be updated on what I have added. Thank you for checking out my site.
        </p>
        
      </div>
      <div>
        <h2>Articles I've Written</h2>
        <div>
          <div><a href="https://www.linkedin.com/pulse/crafting-impactful-data-analytics-reports-guide-jonathan-stroud-jpkae/?trackingId=XAZVt9R9TdSsTUaBavx6Xg%3D%3D" target='_blank'><h3>Crafting Impactful Data Analytics Reports: A Comprehensive Guide</h3></a></div>
          <div><a href="https://www.linkedin.com/pulse/crafting-compelling-bi-reports-blueprint-actionable-insights-stroud-l91ve/?trackingId=XAZVt9R9TdSsTUaBavx6Xg%3D%3D" target='_blank'><h3>Crafting Compelling BI Reports: A Blueprint for Actionable Insights</h3></a></div>
          <div><a href="https://www.linkedin.com/pulse/adobe-analytics-powerhouse-data-insights-jonathan-stroud-hdise/?trackingId=XAZVt9R9TdSsTUaBavx6Xg%3D%3D" target='_blank'><h3>Adobe Analytics: A Powerhouse of Data Insights</h3></a></div>
        </div>
      </div>
    </div>
  );
}
