import React from 'react'

export default function BI_Intel() {
  return (
    <div id='box' className='thelayout' >
        <div>
          <p>
          Welcome to the Business Intelligence and Data Analytics Reports section of my website. Here, you’ll find examples of the analytical reports I have created as part of my continuous journey toward mastering data analysis and business intelligence. While these reports are based on demo and sample data sourced from publicly available open-source platforms, they reflect my growing expertise in data visualization, interpretation, and reporting.
          </p>
          <p>
          As I continue to refine my skills and deepen my knowledge in this field, I welcome any constructive feedback or advice that could help me improve. I am always open to learning from industry professionals and peers to elevate the quality of my work.
          </p>
          <p>
          Please feel free to reach out via email if you have any insights, recommendations, or collaborative opportunities. Your input is invaluable to me as I strive to become more proficient in delivering actionable insights and high-quality reports.
          </p>
          <p>
          Thank you once again for visiting my site and taking the time to review my work. I hope you find these reports insightful, and I look forward to any suggestions that can help me grow in my journey.
          </p>
        </div>
        <div>
          <h2>No Reports Yet - Check back</h2>
        </div>
    </div>
  );
}
