import React from 'react'
import Daz from '../assets/images/logos/daz3d_logo.png'
import Github from '../assets/images/logos/github_logo.png'
import Five from '../assets/images/logos/fiverr_logo.png'
import Free from '../assets/images/logos/freelancer_logo.png'
import Guru from '../assets/images/logos/guru_logo.png'
import Linked from '../assets/images/logos/linkedin_logo.png'
import Render from '../assets/images/logos/renderhub_logo.png'
import Design03 from '../assets/images/g_design03.png'

export default function Contact() {
  return (
    <div className='thelayout'>
      <div className='quotes'>
          <i>"It is only when we take chances that our lives improve. <br></br> The initial and the most difficult risk we need to take is to become honest."</i><br></br>- Walter Anderson
      </div>
      <div className='social'>
        <div>
          <a href='https://github.com/jstroud107' target='_blank' >
            <img src={Github} alt={Github} style={{width: '100px', height: '100px', margin: '5px'}} />
          </a>
        </div>
        <div>
          <a href='https://www.daz3d.com/gallery/user/4941857582743552#gallery=newest&page=1' target='_blank'>
            <img src={Daz} alt={Daz} style={{width: '100px', height: '100px', margin: '5px'}} />
          </a>
        </div>
        <div>
          <a href='https://www.renderhub.com/jstroud107/gallery' target='_blank'>
            <img src={Render} alt={Render} style={{width: '100px', height: '100px', margin: '5px'}} />
          </a>
        </div>
        <div>
          <img src={Guru} alt={Guru} style={{width: '100px', height: '100px', margin: '5px'}} />
        </div>
        <div>
          <a href='https://www.linkedin.com/in/jonathanstroudatl/' target='_blank'>
            <img src={Linked} alt={Linked} style={{width: '100px', height: '100px', margin: '5px'}} />
          </a>
        </div>
        <div>
          <img src={Free} alt={Free} style={{width: '100px', height: '100px', margin: '5px'}} />
        </div>
        <div>
          <img src={Five} alt={Five} style={{width: '100px', height: '100px', margin: '5px'}} />
        </div>
      </div>
      <div className='contact-page'>
      {/*<div>
          <form >
            <label for="name">Name: </label>
            <input type="text" id="name" name="name" required placeholder="Your name..."/>
            <br></br>
            <label for="email">Email: </label>
            <input type="email" id="email" name="email" required placeholder="Your email..."/>
            <br></br>
            <label for="message">Message: </label>
            <textarea id="message" name="message" required placeholder="Write something..." style={{height: '200px'}}></textarea>
            <br></br>
            <button type="submit" value="Submit">Submit</button>
          </form>
          <p>
            <div className='menu-button' style={{width:'50%'}}><a href="" download>Download Resume</a></div>
           <div>Links to cheatsheets for coding</div> 
          </p>
        </div> */}
        <div>
          <img src={Design03} alt={Design03} style={{width: '35%', height: '75%', margin: '5px'}} />
        </div>
      </div>
    </div>
  );
}
