import React from 'react'
import { Link } from 'react-router-dom';

export default function NavBottom() {
  return (
    <nav className='nav-bottom'>
          <div className='menu-button'><Link to='/pripolicy'>Private Policy</Link></div>
           | 
          {/* <div className='menu-button'><Link to='/download'>Download</Link></div>
           |  */}
          <div className='menu-button'><Link to='/contact'>Contact</Link></div>
    </nav>
  );
}
